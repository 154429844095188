import { GET,POST } from '../utils/http'
// import config from '../../constans';
let url = "/api/"
// let url = 'https://www.yugeshop.cn/api/' // uat地址


//pdf链接域名
export const pdfUrl = `${url}`

//微信登录
export const wxLogin = `${url}authWx`
// export const wxLogin = (params) => {
//   return GET(`${url}authWx`,params)
// }

//登录获取验证码
export const getVerifyCode = (params) => {
  return GET(`${url}getVerifyCode`,params)
}

//登录
export const login = (params) => {
  return POST(`${url}login`,params)
}

//首页banner图
export const bannerList = (params) => {
  return GET(`${url}shop/getIndexBanner`,params)
}

//首页文章列表----平台频道type=1
export const contentList = (params) => {
  return POST(`${url}content/getIndexContentList`,params)
}

//首页文章列表----店铺频道type=2
export const shopContentList = (params) => {
  return POST(`${url}shopContent/getIndexShopContentList`,params)
}

//首页商品搜索
export const getSearchList = (params) => {
  return POST(`${url}search`,params)
}
//首页商城搜索
export const keywordSearch = (params) => {
  return POST(`${url}keywordSearch`,params)
}
//首页频道列表
export const channelList = (params) => {
  return GET(`${url}shop/getIndexChannel`,params)
}

//首页金刚位
export const indexDiamond = (params) => {
  return GET(`${url}shop/indexDiamond`,params)
}

//文章详情
export const contentDetail = (contentId,params) => {
  return GET(`${url}content/detail/${contentId}`,params)
}

//文章详情self
export const contentDetailSelf = (contentId,params) => {
  return GET(`${url}shopContent/detail/${contentId}`,params)
}

//获取店铺信息
export const getShopName = async () => {
  let pathName = window.location.pathname;
  let shopName = pathName.split("/")[1];
  let params = {
    shopAlias: shopName,
  };
  // let shopMsg = JSON.parse(localStorage.getItem("shopMsg"))
  // if(shopMsg && shopName === shopMsg.shopAlias){
  //   return shopMsg;
  // }else{
      let data =  Promise.resolve(GET(`${url}shop/getInfo`,params))
      let detail;
      await data.then(function(result){
        localStorage.setItem("shopMsg",JSON.stringify(result.data))
        detail = result.data
      })
      return detail;
  // }
}

//获取trans
export const getTrans = (transId,params) => {
  return GET(`${url}trans/${transId}`,params)
}

//获取所有城市
export const getAllCity = () => {
  return GET(`${url}city/getAll`)
}

//获取所有热门城市
export const getHotCity = () => {
  return GET(`${url}city/getHotCity`)
}

//获取玩耍周边游列表
export const getlyList = (params) => {
  return POST(`${url}ly/getContentList`,params)
}

//获取玩耍周边游推广位列表
export const extendBanner = (params) => {
  return GET(`${url}ly/getLyExtendBanner`,params)
}

//首页热搜词
export const topSearch = (params) => {
  return GET(`${url}topSearch`,params)
}

//获取搜索方法
export const searchMethod = (params) => {
  return GET(`${url}searchMethod`,params)
}

//个人中心获取用户信息
export const getUserInfo = () => {
  return GET(`${url}user/info`)
}

//退出登录
export const logout = () => {
  return GET(`${url}logout`)
}

//收藏里的频道列表
export const favoriteChannelList = (params) => {
  return GET(`${url}favorite/channelList`,params)
}

//加入收藏
export const addFavorite = (params) => {
  return POST(`${url}favorite/addFavorite`,params)
}

//收藏-平台列表
export const merchantList = (params) => {
  return GET(`${url}favorite/merchantList`,params)
}

//收藏-收藏列表
export const getFavoriteList = (params) => {
  return POST(`${url}favorite/getFavoriteList`,params)
}

//取消收藏
export const deleteFavorite = (params) => {
  return POST(`${url}favorite/deleteFavorite`,params)
}

//使用说明
export const getIntro = (params) => {
  return GET(`${url}intro/get`,params)
}

//获取真实购买跳转链接
export const getBuyUrl = (params) => {
  return GET(`${url}cps/redirectUrl`,params)
}
